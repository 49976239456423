import React from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";

const Datacheck = () => {
  const useStyles = makeStyles((theme) => ({
    contain: {
      paddingTop: "10px",
    },
    title: {
      paddingTop: "10px",
      textAlign: "center",
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: "auto",
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    select: {
      width: "100%",
    },
    switch: {
      fontSize: "medium",
    },
  }));
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const token = location.account.token;
  const account = location.account;
  const username = location.username;
  const device = location.device;
  const flatServiceListProps = location.flatServiceList;

  const [registration, setRegistration] = useState("");
  const [summary, setSummary] = useState("");
  const [mileage, setMileage] = useState("");
  const [uid_parent, setUid_parent] = useState(null);
  const [service_parent, setService_parent] = useState({});
  const [flatServiceList, setFlatServiceList] = useState([]);
  const [privateValue, setPrivateValue] = useState(false);
  const [content, setContent] = useState({
    id_vehicule: "",
    date: "",
    user: "",
    content_log: "",
  });
  let serviceList = flatServiceListProps;

  useEffect(() => {
    setFlatServiceList(flatServiceListProps);
    setRegistration(device.registration);
    setSummary(device.summary);
    setUid_parent(device.uid_parent);
    findParent();
  }, []);

  async function validateData() {
    if (registration === "") {
      alert("Veuillez renseigner une immatriculation");
    } else if (uid_parent === "") {
      alert("Veuillez renseigner un service parent");
    } else if (mileage === "") {
      alert("Veuillez renseigner le kilométrage du véhicule");
    } else {
      let deviceUpdate = await fetch(
        // "https://api.dmic.fr/v44/device?action=update" +
        "https://api.dmic.fr/v44/device?action=update" +
          "&token=" +
          token +
          "&uid=" +
          device.uid +
          "&uid_parent=" +
          uid_parent +
          "&summary=" +
          encodeURI(summary) +
          "&registration=" +
          encodeURI(registration),
      );
      let deviceUpdateJson = await deviceUpdate.json();
      let kmUpdate = await fetch(
        "https://api.dmic.fr/v44/update_km?token=" +
          token +
          "&uid_device=" +
          device.uid +
          "&km=" +
          mileage,
      );
      let kmUpdateJson = await kmUpdate.json();
      if (
        (deviceUpdateJson.result === 200 || deviceUpdateJson.result === 201) &&
        deviceUpdateJson.update.affectedRows >= 0 &&
        (kmUpdateJson.result === 200 || kmUpdateJson.result === 201)
      ) {
        // setContent({
        //   id_vehicule: device.uid,
        //   date: Math.round(Date.now() / 1000),
        //   user: username,
        //   content_log:
        //     "VALIDATOR ==> REG:" +
        //     registration +
        //     " KM:" +
        //     mileage +
        //     " DESC:" +
        //     summary +
        //     " VP:" +
        //     privateValue,
        // });
        let newContent = {
          id_vehicule: device.uid,
          date: Math.round(Date.now() / 1000),
          user: username,
          content_log:
            "VALIDATOR ==> REG:" +
            registration +
            " KM:" +
            mileage +
            " DESC:" +
            summary +
            " VP:" +
            privateValue,
        }
        history.push({
          pathname: "/validation",
          account: account,
          device_uid: device.uid,
          privateValue: privateValue,
          content: newContent,
        });
      } else {
        alert("Erreur sur l'envoie des données");
      }
    }
  }

  function findParent() {
    let uid_parent = device.uid_parent;
    serviceList.map((service) => {
      if (service.uid === uid_parent) {
        setService_parent(service);
      }
    });
  }

  function setService(service) {
    setService_parent(service);
    setUid_parent(service.uid);
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.contain}>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.title}>
          <Typography component="h1" variant="h5">
            Veuillez compléter les informations suivantes
          </Typography>
        </div>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="text"
            label="Immatriculation du véhicule"
            name="Immatriculation"
            autoComplete="Immatriculation"
            autoFocus
            value={registration}
            onChange={(registration) =>
              setRegistration(registration.target.value)
            }
          />
          <InputLabel id="service">Service</InputLabel>
          <Select
            required
            className={classes.select}
            variant="outlined"
            labelId="service"
            id="serviceselect"
            value={service_parent}
            onChange={(service) => setService(service.target.value)}
            // key={service.uid}
            label="service"
          >
            {flatServiceList.map((service) => (
              <MenuItem value={service}>{service.label}</MenuItem>
            ))}
          </Select>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Description"
            label="Description du véhicule"
            type="text"
            id="Description"
            autoComplete="Description"
            value={summary}
            onChange={(summary) => setSummary(summary.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Kilométrage"
            label="Kilométrage du véhicule"
            type="text"
            id="Kilométrage"
            autoComplete="Kilométrage"
            value={mileage}
            onChange={(mileage) => setMileage(mileage.target.value)}
          />
          <Switch
            checked={privateValue}
            onChange={(privateValue) =>
              setPrivateValue(privateValue.target.checked)
            }
            color="primary"
            name="privateValue"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <p className={classes.switch}>
            {privateValue
              ? "Capteur vie privée installé"
              : "Sans capteur de vie privée"}
          </p>
        </form>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => validateData()}
        >
          Confirmer les changements
        </Button>
      </div>
    </Container>
  );
};
export default Datacheck;
