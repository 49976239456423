import React from "react";
import { useHistory, useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const AccountsScreen = () => {
  const useStyles = makeStyles((theme) => ({
    contain: {
      paddingTop: "10px",
    },
    title: {
      paddingTop: "10px",
      textAlign: "center",
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: "auto",
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", 
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const username = location.username;
  const accountProps = location.account;
  let accounts = location.siteList.read.data;
  let server = location.server;
  let token = location.siteList.token;

  async function goToIdentification(account) {
    let postauth = await fetch(
      "https://api.dmic.fr/v44/postauth?t=" +
        server +
        "&uid_site=" +
        account.uid +
        "&token=" +
        token,
    );
    let postauthJson = await postauth.json();
    if (postauthJson.result === 200 || postauthJson.result === 201) {
      history.push({
        pathname: "/identification",
        site: account,
        username: username,
        account: accountProps,
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.contain}>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.title}>
          <Typography component="h1" variant="h5">
            Choisir le site
          </Typography>
        </div>
        {accounts.map((account) => (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            key={account.uid + "view"}
            onClick={() => goToIdentification(account)}
          >
            <p key={account.uid} className="serverInstance">
              {account.full_name}{" "}
            </p>
          </Button>
        ))}
      </div>
    </Container>
  );
};

export default AccountsScreen;
