import React from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const IdentificationScreen = () => {
  const useStyles = makeStyles((theme) => ({
    contain: {
      paddingTop: "10px",
    },
    title: {
      paddingTop: "10px",
      textAlign: "center",
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: "auto",
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", 
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const token = location.account.token;
  const account = location.account;
  const site = location.site;
  const username = location.username;

  const [sninternal, setSninternal] = useState("");
  const [device, setDevice] = useState({});
  const [flatServiceList, setFlatServiceList] = useState([]);
  let result = undefined;

  async function checkData() {
    setDevice({});
    let deviceresponse;
    ///////////////TESTING NUMBER////////
    // setSninternal("6977");
    ////////////////////////////////////
    if (sninternal && sninternal !== "") {
      let accountingData = await fetch(
        "https://api.dmic.fr/v44/accounting?token=" + token,
      );
      let accountingDataJson = await accountingData.json();
      setFlatServiceList([]);
      accountingDataJson.accounting.devices.map((element) => {
        if (deviceresponse === undefined && element.type === "device" && element.sninternal === sninternal) {
          setDevice(element);
        } else if (element.type === "service") {
          flatServiceList.push(element);
          if (element.children.length > 0) {
            deviceresponse = getServices(element.children, element.uid);
          }
          flatServiceList[flatServiceList.length - 1].children = [];
        }
      });
      
      if (deviceresponse === undefined) {
        alert("Mauvais identifiant, veuillez vérifier le numéro");
      } else {
        history.push({
          pathname: "/datacheck",
          account: account,
          site: site,
          device: device === undefined ? device : deviceresponse,
          flatServiceList: flatServiceList,
          username: username,
        });
      }
    } else {
      alert("Veuillez entrer un identifiant");
    }
  }

  function getServices(serviceList, uid_parent) {
    serviceList.map((element) => {
      if (result === undefined && element.type === "device" && element.sninternal === sninternal) {
        element.uid_parent = uid_parent;
        setDevice(element);
        result = element;
      } else if (element.type === "service") {
        flatServiceList.push(element);
        if (element.children.length > 0) {
          getServices(element.children, element.uid);
        }
        flatServiceList[flatServiceList.length - 1].children = [];
      }
    });
    return result;
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.contain}>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.title}>
          <Typography component="h1" variant="h5">
            Veuillez indiquer l'identifiant du boitier à valider
          </Typography>
        </div>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="text"
            label="Identifiant du boitier"
            name="text"
            autoComplete="text"
            autoFocus
            value={sninternal}
            onChange={(sninternal) => setSninternal(sninternal.target.value)}
          />
        </form>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => checkData()}
        >
          Commencer la validation
        </Button>
      </div>
    </Container>
  );
};

export default IdentificationScreen;
