// import React from 'react';
import "./App.scss";

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import AccountsScreen from "./component/AccountsScreen";
import HomeScreen from "./component/HomeScreen";
import IdentificationScreen from "./component/IdentificationScreen";
import ServersScreen from "./component/ServersScreen";
import DatacheckScreen from "./component/DatacheckScreen";
import ValidationScreen from "./component/ValidationScreen";
import PrivatelifeScreen from "./component/PrivatelifeScreen";

export default function App() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/servers">
            <ServersScreen></ServersScreen>
          </Route>
          <Route path="/identification">
            <IdentificationScreen></IdentificationScreen>
          </Route>
          <Route path="/accounts">
            <AccountsScreen></AccountsScreen>
          </Route>
          <Route path="/datacheck">
            <DatacheckScreen ></DatacheckScreen>
          </Route>
          <Route path="/validation">
            <ValidationScreen></ValidationScreen>
          </Route>
          <Route path="/privatelife">
            <PrivatelifeScreen></PrivatelifeScreen>
          </Route>
          <Route path="/">
            <HomeScreen></HomeScreen>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
