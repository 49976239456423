import React from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const ServersScreen = () => {
  const useStyles = makeStyles((theme) => ({
    contain: {
      paddingTop: "10px",
    },
    title: {
      paddingTop: "10px",
      textAlign: "center",
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      // margin: theme.spacing(1),
      margin: "auto",
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const token = location.state.preauthData.token;
  const usernameProps = location.state.username;

  const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  // const [preauthToken, setPreauthToken] = useState("");

  let servers = location.state.preauthData.preauth.accounts;

  async function goToAccountList(server) {
    let postauth = await fetch(
      "https://api.dmic.fr/v44/postauth?t=" + server.t + "&token=" + token,
    );
    let postauthJson = await postauth.json();
    if (postauthJson.result === 200 || postauthJson.result === 201) {
      let siteList = await fetch(
        "https://api.dmic.fr/v44/site?action=read&token=" + postauthJson.token,
      );
      let siteListJson = await siteList.json();
      setUsername(usernameProps);
      if (siteListJson.result === 200 || siteListJson.result === 201) {
        history.push({
          pathname: "/accounts",
          account: postauthJson,
          siteList: siteListJson,
          server: server.t,
          username: usernameProps,
        });
      }
    }
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.contain}>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.title}>
          <Typography component="h1" variant="h5">
            Choisir le serveur
          </Typography>
        </div>
        {servers.map((server) => (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            key={server.t + "view"}
            onClick={() => goToAccountList(server)}
          >
            <p key={server.t}>{server.instance} </p>
          </Button>
        ))}
      </div>
    </Container>
  );
};
export default ServersScreen;
