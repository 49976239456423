// import { Icon } from 'react-native-elements';
import React, { useState } from "react";
import md5 from "md5";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const HomeScreen = () => {
  const useStyles = makeStyles((theme) => ({
    contain: {
      paddingTop: "10px",
    },
    title: {
      paddingTop: "10px",
      textAlign: "center",
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      // margin: theme.spacing(1),
      margin: "auto",
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  const classes = useStyles();

  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [preauthToken, setPreauthToken] = useState("");

  async function connection() {
    //////////////////////// TESTING CREDENTIALS////////////////
    // let md5Password = "5998391d30f06aaf672f815c7607869c";
    // setUsername("duncan.legoff@dmic.fr");
    //////////////////////////////////////////////////////////////

    let md5Password = md5(password);

    let preauth = await fetch(
      "https://api.dmic.fr/v44/preauth?u=" + username + "&p=" + md5Password,
    );
    let preauthJson = await preauth.json();
    if (preauthJson.result === 200 || preauthJson.result === 201) {
      if (preauthJson.preauth["accounts"].length == 1) {
        let account = preauthJson.preauth["accounts"][0];
        let postauth = await fetch(
          "https://api.dmic.fr/v44/postauth?t=" +
            account.t +
            "&token=" +
            preauthJson.token,
        );
        let postauthJson = await postauth.json();

        history.push({
          pathname: "/identification",
          account: postauthJson,
          username: username,
        });
      } else {
        setPreauthToken(preauthJson.token);
        history.push({
          pathname: "/servers",
          state: {
            preauthData: preauthJson,
            username: username,
          },
        });
      }
    } else {
      alert("Erreur de connexion");
    }
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.contain}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <div className={classes.title}>
          <Typography component="h1" variant="h5">
            Validator v2.5
          </Typography>
        </div>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={username}
            onChange={(username) => setUsername(username.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(password) => setPassword(password.target.value)}
          />
        </form>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => connection()}
        >
          Connexion
        </Button>
      </div>
    </Container>
  );
};

export default HomeScreen;
