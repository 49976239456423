import React from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const PrivatelifeScreen = () => {
  const useStyles = makeStyles((theme) => ({
    contain: {
      paddingTop: "10px",
    },
    title: {
      paddingTop: "10px",
      textAlign: "center",
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: "auto",
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    message: {
      paddingBottom: "40px",
    },
  }));
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const token = location.account.token;
  const account = location.account;
  const device_uid = location.device_uid;
  // const privateValue = location.privateValue;
  let contentProps = location.content;
  let testingLog = {};
  const [privateLifeOn, setPrivateLifeOn] = useState(false);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(false);
  const [content, setContent] = useState({});

  useEffect(() => {
    startCheckPrivatelife();
  }, []);

  function startCheckPrivatelife() {
    setError(false);
    let count = 0;
    let interval = setInterval(async () => {
      count += 1;
      if (count <= 10) {
        let url =
          "https://api.dmic.fr/v44/location?token=" +
          token +
          "&uid=" +
          device_uid +
          "&timestamp=0";
        let position = await fetch(url);
        let positionJson = await position.json();

        /////////////////TESTING VALIDATION//////////////////////
        if ((positionJson.location.data[0].special_flag & 2) !== 0) {
        // if (0 === 0) {
        /////////////////TESTING VALIDATION//////////////////////

          contentProps.content_log =
            (contentProps.content_log !== undefined ? contentProps.content_log : "") +
            " VPON:true";
          setContent(contentProps);
          setPrivateLifeOn(true);
          clearInterval(interval);
          finishCheckPrivatelife();
        }
      } else {
        clearInterval(interval);
        setError(true);
        alert("Erreur lors de la vérification");
      }
    }, 4000);
  }

  function finishCheckPrivatelife() {
    let count = 0;
    let interval = setInterval(async () => {
      count += 1;
      if (count <= 10) {
        let position = await fetch(
          "https://api.dmic.fr/v44/location?token=" +
            token +
            "&uid=" +
            device_uid +
            "&timestamp=0",
        );
        let positionJson = await position.json();

        /////////////////TESTING VALIDATION//////////////////////
         if ((positionJson.location.data[0].special_flag & 2) == 0) {
        // if (0 === 0) {
        /////////////////TESTING VALIDATION//////////////////////

          // testingLog = content;
          contentProps.content_log =
            (contentProps.content_log !== undefined
              ? contentProps.content_log
              : "") + " VPOFF:true";
          setContent(contentProps);

          // this.state.content.content_log = this.state.content.content_log + ' VPOFF:true';
          let urlvalidation = await fetch(
            "https://api.dmic.fr/v44/validation?action=create" +
              "&token=" +
              token +
              "&uid_device=" +
              contentProps.id_vehicule +
              "&user=" +
              contentProps.user +
              "&date=" +
              contentProps.date +
              "&content_log=" +
              contentProps.content_log,
          );
          clearInterval(interval);
          setFinished(true);
        }
      } else {
        clearInterval(interval);
        setError(true);
        setPrivateLifeOn(false);
        alert("Erreur lors de la vérification");
      }
    }, 4000);
  }

  function newValidation() {
    history.push({
      pathname: "/identification",
      account: account,
      username: contentProps.user
    });
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.contain}>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.title}>
          <Typography component="h3" variant="h5" className={classes.message}>
            Veuillez activer la vie privée sur le capteur
          </Typography>
          {privateLifeOn === true ? (
            <Typography component="h3" variant="h5" className={classes.message}>
              Veuillez désactiver la vie privée sur le capteur
            </Typography>
          ) : (
            <div></div>
          )}
          {finished === true ? (
            <Typography component="h1" variant="h5" className={classes.message}>
              Validation terminée !
            </Typography>
          ) : (
            <div></div>
          )}
        </div>
        {error === true ? (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => startCheckPrivatelife()}
          >
            Recommencer la vérification de vie privée
          </Button>
        ) : (
          <div></div>
        )}
        {finished === true ? (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => newValidation()}
          >
            Nouvelle validation
          </Button>
        ) : (
          <div></div>
        )}
      </div>
    </Container>
  );
};

export default PrivatelifeScreen;
